<template>
	<div>
		<CreateDialog :dialog="createDialog" v-on:close="closeCreateDialog">
			<template v-slot:title>
				<span>{{ pageTitle() }}</span>
				<span class="orange--text text--darken-4 barcode-font-size">
					#{{ project_transfer.barcode }}</span
				>
			</template>
			<template v-slot:body>
				<v-sheet class="project" id="project" style="height: calc(100vh - 88px)">
					<v-row>
						<v-col md="12">
							<v-form
								ref="projectForm"
								v-model.trim="formValid"
								lazy-validation
								v-on:submit.stop.prevent="updateOrCreate"
							>
								<v-row>
									<v-col md="9">
										<perfect-scrollbar
											:options="{ suppressScrollX: true }"
											class="scroll custom-box-top-inner-shadow"
											style="position: relative"
										>
											<v-row class="px-4">
												<v-col md="3" class="py-0">
													<label class="mt-4 required">Project Transfer Date</label>
												</v-col>
												<v-col md="3" class="py-0">
													<DatePicker
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														:rules="[vrules.required(project_transfer.transfer_date, 'Transfer Date')]"
														:class="{ required: !project_transfer.transfer_date }"
														id="transfer-date"
														v-model="project_transfer.transfer_date"
														@change="saveToLocal()"
													>
													</DatePicker>
												</v-col>
												<v-col md="6"></v-col>
												<v-col md="3" class="py-0">
													<label class="mt-4 required">Project Transfer From</label>
												</v-col>
												<v-col md="9" class="py-0">
													<AutoCompleteInput
														hide-details
														:items="projectFromList"
														:disabled="pageLoading"
														:loading="pageLoading"
														id="group"
														placeholder="Project Transfer From"
														item-text="name_barcode"
														item-value="projects_id"
														v-model="project_transfer.project_from"
														:rules="[vrules.required(project_transfer.project_from, 'Transfer From')]"
														:class="{ required: !project_transfer.project_from }"
														@change="changProjectFrom(project_transfer.project_from)"
													></AutoCompleteInput>
													<div class="d-flex">
														<div class="pa-3" v-if="fromProject">
															<div class="blue--text text--darken-4 text-uppercase">
																<b>Customer Detail</b>
															</div>
															<p class="mb-0" v-if="fromProject?.customer_company">
																<v-icon size="16" class="mr-1">mdi-home-city-outline</v-icon>
																<ShowValue
																	tooltip
																	tooltip-text="Company name"
																	:object="fromProject"
																	object-key="customer_company"
																	label="company name"
																></ShowValue>
															</p>
															<p class="mb-0" v-if="fromProject?.customer_name">
																<v-icon size="16" class="mr-1">mdi-account</v-icon>
																<ShowValue
																	tooltip
																	tooltip-text="customer name"
																	:object="fromProject"
																	object-key="customer_name"
																	label="customer name"
																></ShowValue>
															</p>
															<p class="mb-0" v-if="fromProject?.customer_phone_no">
																<v-icon size="16" class="mr-1">mdi-phone</v-icon>
																<ShowValue
																	tooltip
																	click-to-copy
																	tooltip-text="phone number"
																	:object="fromProject"
																	object-key="customer_phone_no"
																	label="phone number"
																></ShowValue>
															</p>
															<p class="mb-0 text-truncate" v-if="fromProject?.customer_email">
																<v-icon size="16" class="mr-1">mdi-email</v-icon>
																<ShowValue
																	tooltip
																	click-to-copy
																	tooltip-text="email"
																	:object="fromProject"
																	object-key="customer_email"
																	label="email"
																></ShowValue>
															</p>
														</div>

														<div class="pa-3" v-if="fromProject">
															<div class="blue--text text--darken-4 text-uppercase">
																<b>Billing Address</b>
															</div>
															<p class="mb-0 text-lowercase" v-if="fromProject?.location_name">
																<v-icon left small>mdi-account</v-icon>
																{{ fromProject.location_name }}
															</p>
															<p class="mb-0 text-lowercase" v-if="fromProject?.location_phone_no">
																<v-icon left small>mdi-phone</v-icon>{{ fromProject?.location_phone_no }}
															</p>
															<p class="mb-0 text-lowercase" v-if="fromProject?.location_email">
																<v-icon left small>mdi-email</v-icon>
																{{ fromProject?.location_email }}
															</p>
															<div class="mb-0 d-flex">
																<div>
																	<v-icon left small>mdi-map</v-icon>
																</div>
																<div>
																	<p class="mb-1" v-if="fromProject?.location_address_line_1">
																		{{ fromProject?.location_address_line_1 }}
																	</p>
																	<p class="mb-1" v-if="fromProject?.location_address_line_2">
																		{{ fromProject?.location_address_line_2 }}
																	</p>
																	<p class="mb-1">
																		<template v-if="fromProject?.location_address_country">
																			{{ fromProject?.location_address_country }}
																		</template>
																		<template v-if="fromProject?.location_address_postal_code">
																			{{ fromProject?.location_address_postal_code }}
																		</template>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</v-col>
												<v-col md="3" class="py-0">
													<label class="mt-4 required">Project Transfer To</label>
												</v-col>
												<v-col md="9" class="py-0">
													<AutoCompleteInput
														hide-details
														:items="projectToList"
														:disabled="pageLoading"
														:loading="pageLoading"
														id="group"
														placeholder="Project Transfer To"
														item-text="name_barcode"
														:rules="[vrules.required(project_transfer.project_to, 'Transfer To')]"
														:class="{ required: !project_transfer.project_to }"
														item-value="projects_id"
														@change="changProjectTo(project_transfer.project_to)"
														v-model="project_transfer.project_to"
													></AutoCompleteInput>
													<div class="d-flex">
														<div class="pa-3" v-if="projectTo">
															<div class="blue--text text--darken-4 text-uppercase">
																<b>Customer Detail</b>
															</div>
															<p class="mb-0" v-if="projectTo.customer_company">
																<v-icon size="16" class="mr-1">mdi-home-city-outline</v-icon>
																<ShowValue
																	tooltip
																	tooltip-text="Company name"
																	:object="projectTo"
																	object-key="customer_company"
																	label="company name"
																></ShowValue>
															</p>
															<p class="mb-0" v-if="projectTo.customer_name">
																<v-icon size="16" class="mr-1">mdi-account</v-icon>
																<ShowValue
																	tooltip
																	tooltip-text="customer name"
																	:object="projectTo"
																	object-key="customer_name"
																	label="customer name"
																></ShowValue>
															</p>
															<p class="mb-0" v-if="projectTo.customer_phone_no">
																<v-icon size="16" class="mr-1">mdi-phone</v-icon>
																<ShowValue
																	tooltip
																	click-to-copy
																	tooltip-text="phone number"
																	:object="projectTo"
																	object-key="customer_phone_no"
																	label="phone number"
																></ShowValue>
															</p>
															<p class="mb-0 text-truncate" v-if="projectTo.customer_email">
																<v-icon size="16" class="mr-1">mdi-email</v-icon>
																<ShowValue
																	tooltip
																	click-to-copy
																	tooltip-text="email"
																	:object="projectTo"
																	object-key="customer_email"
																	label="email"
																></ShowValue>
															</p>
														</div>

														<div class="pa-3" v-if="projectTo">
															<div class="blue--text text--darken-4 text-uppercase">
																<b>Billing Address</b>
															</div>
															<p class="mb-0 text-lowercase" v-if="projectTo?.location_name">
																<v-icon left small>mdi-account</v-icon>
																{{ projectTo?.location_name }}
															</p>
															<p class="mb-0 text-lowercase" v-if="projectTo?.location_phone_no">
																<v-icon left small>mdi-phone</v-icon>{{ projectTo?.location_phone_no }}
															</p>
															<p class="mb-0 text-lowercase" v-if="projectTo?.location_email">
																<v-icon left small>mdi-email</v-icon>
																{{ projectTo?.location_email }}
															</p>
															<div class="mb-0 d-flex">
																<div>
																	<v-icon left small>mdi-map</v-icon>
																</div>
																<div>
																	<p class="mb-1" v-if="projectTo?.location_address_line_1">
																		{{ projectTo?.location_address_line_1 }}
																	</p>
																	<p class="mb-1" v-if="projectTo?.location_address_line_2">
																		{{ projectTo?.location_address_line_2 }}
																	</p>
																	<p class="mb-1">
																		<template v-if="projectTo?.location_address_country">
																			{{ projectTo?.location_address_country }}
																		</template>
																		<template v-if="projectTo?.location_address_postal_code">
																			{{ projectTo?.location_address_postal_code }}
																		</template>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</v-col>
												<v-col md="3" class="py-0">
													<label for="remark" class="btx-label mt-4">Remark</label>
												</v-col>
												<v-col md="9" class="py-0">
													<TextAreaInput
														placeholder="Remark"
														v-model="project_transfer.remark"
														:disabled="pageLoading"
														id="remark"
														:loading="pageLoading"
														@change="saveToLocal()"
													></TextAreaInput>
												</v-col>

												<v-col md="3" class="py-0">
													<label for="attachment" class="btx-label mt-4">Documents/Attachment</label>
												</v-col>
												<v-col md="9" class="py-0">
													<FileUpload :allowAddMore="false" v-model="project_transfer.files"></FileUpload>
												</v-col>
												<!-- Start line item  -->
												<v-col md="12">
													<perfect-scrollbar
														:options="{ suppressScrollX: true }"
														class="scroll custom-box-top-inner-shadow"
														style="position: relative; max-height: 500px"
													>
														<div class="grey lighten-3 mt-8 pa-2">
															<span class="font-level-3-bold">
																<!-- Product/Service -->

																<v-tabs
																	v-model="projectTransferTab"
																	background-color="transparent"
																	color="white"
																	active-class="grey  text--black"
																	hide-slider
																	style="position: sticky; top: 0; z-index: 99"
																>
																	<v-tab @click="tabChange('inventory')" href="#inventory">
																		<span class="tab-svg-icon mr-2">
																			<!--begin::Svg Icon-->
																			<inline-svg
																				style="fill:'#fff"
																				:src="$assetURL('media/custom-svg/inventory-1.svg')"
																			/>
																			<!--end::Svg Icon-->
																		</span>
																		Products
																	</v-tab>
																	<!-- <v-tab @click="tabChange('service')" href="#service" class="mx-4">
																		<span class="tab-svg-icon mr-2">
																		
																			<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
																			
																		</span>
																		Services
																	</v-tab> -->
																	<v-tab @click="tabChange('equipments')" href="#equipments" class="mx-4">
																		<span class="tab-svg-icon mr-2">
																			<!--begin::Svg Icon-->
																			<inline-svg :src="$assetURL('media/custom-svg/inventory-1.svg')" />
																			<!--end::Svg Icon-->
																		</span>
																		Equipments
																	</v-tab>
																</v-tabs>

																<template>
																	<div class="mb-4 w-100 po-line-items mr-4 bt-table table">
																		<table
																			style="max-height: calc(100vh-200px)"
																			class="w-100 bt-table listing-table table-head-sticky"
																			width="100%"
																		>
																			<thead>
																				<tr>
																					<!-- <th class="pa-2 text-center" style="font-weight: 600" width="40">#</th> -->
																					<th class="pa-2" min-width="100" style="font-weight: 600">Items</th>
																					<th class="pa-2" width="400" style="font-weight: 600">Description</th>
																					<th class="pa-2" width="350" style="font-weight: 600">Remark</th>
																					<th class="pa-2" width="100" style="font-weight: 600">Quantity</th>
																					<th class="pa-2" width="50" style="font-weight: 600">Request</th>
																					<th class="pa-2" width="50" style="font-weight: 600"></th>
																				</tr>
																			</thead>
																			
																			<tbody v-clickoutside="outsideClicked">
																				<tr v-for="(row, index) in allLineItem" :key="index" class="">
																					<template v-if="row?.isSelected">
																						<!-- <td class="py-1 px-2 text-center">{{ index + 1-otherLineItemCount }}</td> -->
																						<td
																							class="py-1 px-2 d-flex justify-space-between"
																							@click="editRow($event, index, row.isEditable)"
																							:class="row.isEditable ? 'px-2' : 'px-3'"
																						>
																						<!-- {{ row }} -->
																							<div>
																								<template v-if="row.product_type == 'equipments'">
																									<!-- <AutoCompleteInput
																										hide-details
																										v-if="row.isEditable"
																										:items="equipmentDropdownList"
																										:disabled="pageLoading"
																										:loading="pageLoading"
																										id="group"
																										placeholder="Equipment"
																										item-text="text"
																										item-value="value"
																										v-model="row.item_id"
																										class="mt-0 mr-2"
																									></AutoCompleteInput> -->
																									<template >{{ row.product_info }}</template> 
																									<!-- getEquipmentName(row.item_id)-->
																									<!-- v-else -->
																								</template>
																								<template v-else>
																									<!-- <AutoCompleteInput
																										hide-details
																										:items="productList"
																										:disabled="pageLoading"
																										:loading="pageLoading"
																										id="group"
																										v-if="row.isEditable"
																										placeholder="Product"
																										item-text="text"
																										item-value="value"
																										v-model="row.product"
																										class="mt-0 mr-2"
																									></AutoCompleteInput> -->
																									<template>{{ row.product_info }}</template>
																									<!--getProductName(row.product)-->
																								</template>
																							</div>
																							<v-chip v-if="row.product_type=='inventory' || row.product_type=='service'"
																								style="color: white"
																								label
																								color="blue darken-4"
																								class="text-capitalize white--text"
																								>{{
																									row.product_type == "inventory" ? "P" : (row.product_type=='service'?'S':'E')
																								}}</v-chip
																							>
																							<v-chip v-else
																								style="color: white"
																								label
																								color="orange darken-4"
																								class="text-capitalize white--text"
																								>{{
																									row.product_type == "inventory" ? "P" : (row.product_type=='service'?'S':'E')
																								}}</v-chip
																							>
																						</td>
																						<td
																							style="border-top: 1px solid #c7c7c7;max-width: 250px"
																							class="py-1 px-2 text-truncate"
																							max-width="400"
																							@click="editRow($event, index)"
																							:class="row.isEditable ? 'px-2' : 'px-3'"
																						>
																							<TextInput
																								v-if="row.isEditable"
																								hide-details
																								:disabled="pageLoading"
																								:loading="pageLoading"
																								v-model="row.description"
																								class="mt-0"
																							></TextInput>
																							<template v-else>{{ row.description }}</template>
																						</td>

																						<td
																							style="border-top: 1px solid #c7c7c7;max-width: 250px"
																							class="py-1 px-2 text-truncate"
																							@click="editRow($event, index)"
																							:class="row.isEditable ? 'px-2' : 'px-3'"
																						>
																							<template>
																								<TextInput
																									v-if="row.isEditable"
																									hide-details
																									:loading="pageLoading"
																									v-model="row.remark"
																									class="mt-0"
																								></TextInput>
																								<template v-else>{{ row.remark }}</template>
																							</template>
																						</td>
																						<td
																							style="border-top: 1px solid #c7c7c7"
																							class="py-1 px-2"
																							@click="editRow($event, index)"
																							:class="row.isEditable ? 'px-2' : 'px-3'"
																						>
																							
																							<NumberInput
																								v-if="row.isEditable && (row.product_type!='equipment' && row.product_type!='equipments' )"
																								disabled
																								hide-details
																								type="number"
																								
																								:loading="pageLoading"
																								v-model="row.quantity"
																								class="mt-0"
																							></NumberInput>
																							<template v-else>{{ row.product_type!='equipments' ? row.quantity:null }}</template>
																						</td>
																						<!-- <td
																							class="py-1 px-2"
																							@click="editRow($event, index)"
																							:class="row.isEditable ? 'px-2' : 'px-3'"
																						>
																							<NumberInput
																								v-if="row.isEditable"
																								disabled
																								hide-details
																								type="number"
																								:rules="[vrules.required(row.quantity, 'Quantity')]"
																								:class="{
																									required: !row.quantity,
																								}"
																								:loading="pageLoading"
																								v-model="row.quantity"
																								class="mt-0"
																							></NumberInput>
																							<template v-else>{{ row.quantity }}</template>
																						</td> -->
																						<td
																							class="py-1 px-2"
																							style="border-top: 1px solid #c7c7c7"
																							@click="editRow($event, index)"
																							:class="row.isEditable ? 'px-2' : 'px-3'"
																						>
																							<NumberInput
																								v-if="row.isEditable"
																								hide-details
																								type="number"
																								@keyup="lineItemRequestKeyup(row.quantity, row.request_quantity, index)"
																								:rules="[vrules.required(row.request_quantity, 'Request')]"
																								:class="{
																									required: !row.request_quantity,
																								}"
																								:disabled="pageLoading"
																								:loading="pageLoading"
																								v-model.number="row.request_quantity"
																								class="mt-0"
																							></NumberInput>
																							<template v-else>{{ row.request_quantity }}</template>
																						</td>
																						<td style="border-top: 1px solid #c7c7c7" class="py-1 px-2 text-right">
																							<v-icon
																								color="red"
																								:disabled="productLineItems.length == 1 ? true : false"
																								class="cursor-pointer"
																								@click="deleteLineItems(index)"
																								>mdi-delete-outline</v-icon
																							>
																						</td>
																					</template>
																				</tr>
																			</tbody>
																			<!-- <tfoot>
																<v-btn
																	color="blue darken-4 white--text"
																	class="mt-2"
																	tile
																	depressed
																	v-on:click="addLineItems()"
																>
																	<v-icon>mdi-plus</v-icon> Add More...
																</v-btn>
															</tfoot> -->
																		</table>
																	</div>
																</template>
															</span>
														</div>
													</perfect-scrollbar>
												</v-col>
												<!-- End line item  -->

												<!-- Start Equipment Item  -->
												<!-- <v-col md="12">
													<ProjectTransferEquipment
														ref="projectTransferEquipment"
														:equipment_list="equipment_list"
													/>
												</v-col> -->
												<!-- End Equipment Item  -->
											</v-row>
										</perfect-scrollbar>
									</v-col>
								</v-row>
							</v-form>
						</v-col>
					</v-row>
				</v-sheet>
			</template>
			<template v-slot:action>
				<v-btn class="white--text" depressed color="blue darken-4" @click="updateOrCreate" tile>
					Save
				</v-btn>
			</template>
		</CreateDialog>
		<AddProductLineItemDialog
			v-if="isProductLineItemDialog"
			@saveLineItem="saveLineItem"
			:productList="productList"
			:equipmentDropdownList="equipmentDropdownList"
			:type="projectTransferTab"
			v-model="copyAllLineItem"
			:isProductLineItemDialog="isProductLineItemDialog"
			@close="isProductLineItemDialog = false"
		/>
		<AddServiceLineItemDialog
			:productList="productList"
			v-model="serviceLineItems"
			:addServiceLineItemDialog="addServiceLineItemDialog"
			@close="addServiceLineItemDialog = false"
		/>
		<AddEquipmentDialog
			:equipmentList="equipmentDropdownList"
			v-model="equipmentLineItems"
			:addEquipmentLineItemDialog="addEquipmentLineItemDialog"
			@close="addEquipmentLineItemDialog = false"
		/>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import {
	// updateProjectTransfer,
	GetProjectList,
	GetProjectTransfer,
	CreateProjectTransfer,
	ProjectLineItems,
	EquipmentAllList,
} from "@/core/lib/project-transfer.lib.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { cloneDeep } from "lodash";
import TextInput from "@/view/components/TextInput";
import NumberInput from "@/view/components/NumberInput";
import ShowValue from "@/view/components/ShowValue";
import FileUpload from "@/view/components/FileUpload";
// import ProjectTransferEquipment from "../../module/project-transfer/CreateProjectEquipment";
import CreateDialog from "@/view/components/CreateDialog";
import { saveData, deleteData } from "@/core/services/local.service";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import "@/core/event-bus/click-outside";
import { GetAllProduct } from "@/core/lib/common.lib";
// import Dialog from "@/view/components/Dialog";
import AddServiceLineItemDialog from "../../module/project-transfer/AddServiceLineItemDialog";
import AddEquipmentDialog from "../../module/project-transfer/AddEquipmentDialog";
import AddProductLineItemDialog from "../../module/project-transfer/AddProductLineItemDialog";
// import {} from "@/core/lib/project-transfer.lib.js";
export default {
	name: "project-create",
	title: "Create Project",
	data() {
		return {
			uuid: null,
			allLineItem: [],
			isProductLineItemDialog: false,
			otherLineItemCount: 0,
			addServiceLineItemDialog: false,
			addEquipmentLineItemDialog: false,
			productList: [{ item: null, description: null, qty: null, request: null }],
			equipmentLineItems: [{ item: null, description: null, qty: null, request: null }],
			line_items: [
				{
					item: null,
					description: null,
					qty: null,
					request: null,
				},
			],
			dialogProductLineItems: [
				{
					item: null,
					description: null,
					qty: null,
					request: null,
				},
			],
			service_line_items: [
				{
					item: null,
					description: null,
					qty: null,
					request: null,
				},
			],
			productLineItems: [
				{
					item: null,
					description: null,
					qty: null,
					request: null,
				},
			],
			serviceLineItems: [
				{
					item: null,
					description: null,
					qty: null,
					request: null,
				},
			],
			equipment_list: [],
			copyAllLineItem: [],
			projectTransferTab: "product",
			barcode: null,
			formValid: true,
			disabled: false,
			equipmentDropdownList: [],
			teamMembersList: [],
			pageLoading: false,
			attachment: [],
			bankList: [],
			insuranceBankList: [],
			propertyList: [],
			manageBankDialog: false,
			manageInsuranceBankDialog: false,
			lineItemDialog: false,
			hideMoreChip: false,
			customerList: [],
			projectDetail: null,
			projectList: [],
			customer_contact_person: null,
			memberList: [],
			selectedCustomer: {
				id: null,
				contact_person_id: null,
				property_id: null,
			},
			project_transfer: {
				transfer_date: null,
				project_from: null,
				project_to: null,
				remark: null,
				files: [],
			},
			isOutside: true,

			firstTimes: true,
			project: {
				id: null,
				uuid: null,
				contact: null,
				title: null,
				// property: null,
				attachment: [],
				customer: {
					id: null,
					contact_person_id: null,
					property_id: null,
				},
				remark: null,
				start_date: null,
				end_date: null,
				members: [],
				description: null,
				value: null,
				customer_purchase_order: null,
				quotations: [],
				references_no: null,
				amount: null,
				team_members: [],
				budget: null,
				specification: null,
				bankers_guarantee: {
					bank: null,
					amount: null,
					remark: null,
					attachment: [],
				},
				insurance_bank: {
					insurance_bank: null,
					amount: null,
					remark: null,
					attachment: null,
				},
			},
			createDialog: true,
			purchaseOrderItem: [],
			quoteItems: [],
			customerItems: [],
			fromProject: null,
			projectTo: null,
		};
	},
	components: {
		TextAreaInput,
		// Dialog,
		ShowValue,
		DatePicker,
		AutoCompleteInput,
		TextInput,
		CreateDialog,
		FileUpload,
		// ProjectTransferEquipment,
		AddProductLineItemDialog,
		NumberInput,
		AddServiceLineItemDialog,
		AddEquipmentDialog,
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		projectFromList() {
			return this.projectList.filter(
				(project) => project?.projects_id != this.project_transfer.project_to
			);
		},
		projectToList() {
			return this.projectList.filter(
				(project) => project?.projects_id != this.project_transfer.project_from
			);
		},
	},
	methods: {
		saveLineItem(selectedItem) {
			//console.log(selectedItem);
			this.allLineItem = cloneDeep(selectedItem);
			this.allLineItem.map((ele) => {
				return { ...ele, item_id: ele.product };
			});
			this.isProductLineItemDialog = false;
		},
		getEquipmentName(id) {
			const product = this.equipmentDropdownList.find((row) => row.value == id);
			if (product) {
				return product.text;
			} else {
				return null;
			}
		},
		lineItemRequestKeyup(quantity, request, index) {
			if (request > quantity) {
				this.allLineItem[index].request = this.allLineItem[index].quantity;
			}
		},
		usedIsCheckedLineItems() {
			if (this.projectTransferTab == "product") {
				this.line_items = cloneDeep(this.dialogProductLineItems.filter((item) => item?.isSelected));
			}
			this.line_items = this.line_items.map((ele) => {
				return { ...ele, isEditable: true };
			});
			this.lineItemDialog = false;
		},
		deleteLineItems(index) {
			this.allLineItem.splice(index, 1);
		},
		tabChange() {
			// if (type == "inventory") {
			this.isProductLineItemDialog = true;
			// this.otherLineItemCount = this.allLineItem.filter(
			// 	(ele) => ele.product_type != this.projectTransferTab
			// ).length;
			// }
		},
		addLineItems() {
			const _order = this.line_items.length;
			this.line_items.push({
				isEditable: false,
				id: null,
				uuid: null,
				product: null,
				description: null,
				uom: null,
				quantity: 1,
				rate: 0,
				total: 0,
				order: _order + 1,
			});
		},

		editRow(event, index) {
			this.isOutside = false;
			const element = event.target;
			const _line_items = this.allLineItem.map((row) => {
				return { ...row, isEditable: false };
			});
			this.allLineItem = _line_items;
			this.allLineItem[index].isEditable = true;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
		},

		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.projectForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.projectForm.validate()) {
				return false;
			}
			let isValid = 1;
			// validating line item request quantity
			//console.log(this.allLineItem);
			for (let index = 0; index < this.allLineItem.length; index++) {
				if (!this.allLineItem[index].request_quantity && this.allLineItem[index]?.isSelected) {
					this.$store.commit(SET_ERROR, [
						{ model: true, message: `${index + 1} Product request quantity is required!` },
					]);
					isValid = 0;
					break;
				}
			}
			if (isValid == 0) {
				return false;
			}
			
			let formData = {
				uuid: null,
				transfer_date: this.project_transfer.transfer_date,
				from: this.project_transfer.project_from,
				to: this.project_transfer.project_to,
				remark: this.project_transfer.remark,
				files: this.project_transfer.files,
			};
			try {
				_this.pageLoading = true;
				if (_this.uuid) {
				
					formData.uuid = _this.uuid;
					let productLineItem = [];
					productLineItem = this.allLineItem.filter((product) => product.isSelected);
					
					productLineItem = productLineItem.map((item) => {
						
						return {
							id: item.exist=='true'?item.id:null, //item.id?item.id:0
							item_id: item.item_id?item.item_id:0,
							product: item.product?item.product:0,
							type: item?.product_type,
							description: item?.description || null,
							remark: item?.remark || null,
							quantity: item?.quantity || null,
							request_quantity: item?.request_quantity || null,
						};
					});
					//console.log(productLineItem);
					formData = { ...formData, items: productLineItem };
					//console.log(formData);

					const { transfer_projects } = await CreateProjectTransfer(formData);
					const uuid = transfer_projects.uuid;
					this.uuid = uuid;
					
					_this.$router.replace({
						name: "project_transfer_details",
						params: { uuid },
						query: { t: new Date().getTime() },
					});
					
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Project Transfer has been updated." },
					]);
				} else {
					let productLineItem = [];
					productLineItem = this.allLineItem.filter((product) => product.isSelected);
					productLineItem = productLineItem.map((item) => {
						return {
							id: null,
							item_id: item.id,
							type: item?.product_type,
							product: item.product?item.product:0,
							description: item?.description || null,
							remark: item?.remark || null,
							quantity: item?.quantity || null,
							request_quantity: item?.request_quantity || null,
						};
					});
					
					formData = { ...formData, items: productLineItem };
					console.log(formData);

					const { transfer_projects } = await CreateProjectTransfer(formData);
					const uuid = transfer_projects.uuid;
					this.uuid = uuid;
					_this.$router.replace({
						name: "project-transfer",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Project Transfer has been created." },
					]);
				}
				// clearing local storage data
				deleteData("create_project");
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		outsideClicked() {
			if (!this.isOutside) {
				const _line_items = this.line_items.map((row) => {
					return { ...row, isEditable: false };
				});
				this.line_items = _line_items;
				this.isOutside = true;
			}
		},

		saveToLocal() {
			if (this.$route.name === "project-create") {
				const create_project = {
					customer: this.project.customer,
					start_date: this.project.start_date,
					end_date: this.project.end_date,
					title: this.project.title,
					quotations: this.project.quotations,
					references_no: this.project.references_no,
					specification: this.project.specification,
					team_members: this.project.team_members,
					description: this.project.description,
					value: this.project.value,
					budget: this.project.budget,
					bank: this.project.bankers_guarantee.bank,
					bankers_guarantee_amount: this.project.bankers_guarantee.amount,
					bankers_guarantee_remark: this.project.bankers_guarantee.remark,
					insurance_bank: this.project.insurance_bank.insurance_bank,
					insurance_amount: this.project.insurance_bank.amount,
					insurance_remark: this.project.insurance_bank.remark,
				};
				saveData("create_project", create_project);
			}
		},

		closeCreateDialog() {
			this.createDialog = false;
			deleteData("create_project");
		},
		projectBudgetKeyUp() {
			if (this.project.budget > this.project.value) {
				this.project.budget = this.project.value;
			}
		},
		getProductName(id) {
			const product = this.productList.find((row) => row.value == id);
			if (product) {
				return product.text;
			} else {
				return null;
			}
		},
		async getProjectTransfer() {
			try {
				const uuid = this.$route.params.uuid;
				const data = await GetProjectTransfer(uuid);
				this.project_transfer.barcode = data.project_transfer.transfer_projects_barcode;
				this.project_transfer.project_from = data.project_transfer.transfer_from;
				this.project_transfer.project_to = data.project_transfer.transfer_to;
				this.project_transfer.transfer_date = data.project_transfer.transfer_date;
				this.project_transfer.transfer_date = data.project_transfer.transfer_date;
				this.project_transfer.remark = data.project_transfer.remark;
				this.uuid = this.$route.params.uuid?this.$route.params.uuid:null;
				await this.changProjectFrom(this.project_transfer.project_from);

				this.changProjectTo(this.project_transfer.project_to);
				this.allLineItem = data.line_items.map((row)=>{
					return {...row,isSelected:true}
				});

				//console.log("	data.line_items ", data.line_items);
				//console.log("	this.copyAllLineItem ", this.copyAllLineItem);
				
				this.copyAllLineItem = this.copyAllLineItem.map((elem) => {
					const exist = this.allLineItem.some((ele) => {
						return (
							elem?.product == ele?.item_id &&
							data.project_transfer.transfer_projects_id == ele?.transfer_id
						);
					});
					if(!this.uuid){
						if (exist) {
						return { ...elem, isSelected: true };
						} else {
							let isSelected = elem.isSelected ? elem.isSelected : false;
							let selectedItem = false;
							if(isSelected){selectedItem=true;}
							return { ...elem, isSelected: selectedItem };
						}
					}else{
						let isSelected = elem.isSelected ? elem.isSelected : false;
						let selectedItem = false;
						if(isSelected){selectedItem=true;}
						return { ...elem, isSelected: selectedItem };
					}
					
				});

				// console.log("this.copyAllLineItem", this.copyAllLineItem);

				this.allLineItem = this.allLineItem.map((lineItem) => {
					return {
						...lineItem,
						isSelected: true,
						item_id: lineItem.item_id,
						// product_type:
						// 	lineItem?.product_type == 1 ? "inventory" : lineItem?.product_type == 2 ? "service" : "equipments",
					};
				});
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		pageTitle() {
			//alert(this.uuid);
			const uuid = this.$route.params.uuid;
			if (uuid) {
				//console.log(project);
				//const pName = this.project.name;
				return "Update Project Transfer";
			}
			return "Create New Project Transfer";
		},
		async changProjectFrom() {
			try {
				let uuidOnUpdate = this.uuid ? this.uuid : '';
				const project = this.projectFromList.find(
					(project) => this.project_transfer.project_from == project.projects_id
				);
				this.fromProject = project;
				
				//console.log(project.projects_uuid,"ss");
				
				//  if( !project.projects_uuid){
				// 	this.$store.commit(SET_ERROR, [{ model: true, message: 'Transfer project from is required' }]);
				// 	return false;
				// }
				if(project?.projects_uuid){
					const data = await ProjectLineItems(project?.projects_uuid,uuidOnUpdate);
					this.copyAllLineItem = [...data.products, ...data.equipments, ...data.services];
				
				this.productLineItems = cloneDeep(data.products);
				this.serviceLineItems = cloneDeep(data.services);
				this.equipmentLineItems = cloneDeep(data.equipments);
				this.equipment_list = data.equipments;
				}
				// this.allLineItem = [...data.products, ...data.equipments, ...data.services];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		async changProjectTo() {
			const project = this.projectToList.find(
				(project) => this.project_transfer.project_to == project.projects_id
			);
			this.projectTo = project;
		},
		async getEquipments() {
			try {
				const list = await EquipmentAllList();
				if (list) {
					const data = list.map((ele) => {
						return { ...ele, value: ele.id, text: `${ele.barcode} - ${ele.name}` };
					});
					this.equipmentDropdownList = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getAllProduct() {
			try {
				const notUndefined = anyValue => typeof anyValue !== 'undefined' 
				const { list } = await GetAllProduct();
				if (list) {
					const data = list.map((ele) => {
						if(ele.product_type=='inventory' || ele.product_type=='product'){
							return { ...ele, value: ele.id, text: `${ele.barcode} - ${ele.name}` };
						}
						
					}).filter(notUndefined);
					this.productList = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
	async mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Project Transfer", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { projects, barcode } = await GetProjectList();
		await this.getAllProduct();
		const data = projects.map((ele) => {
			return { ...ele, name_barcode: `${ele.projects_barcode}-${ele.projects_name}` };
		});
		await this.getEquipments();
		this.projectList = data;
		this.pageLoading = false;
		if (!this.$route.params?.uuid) {
			this.project_transfer.barcode = barcode;
		} else {
			await this.getProjectTransfer();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
<style scoped>
.v-text-field__details {
	display: none !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
	display: none;
}
</style>
<style scoped>
.po-line-items th {
	font-weight: 500;
}
.po-line-items th,
.po-line-items td:not(.borer-none) {
	border-bottom: 1px solid #c7c7c7;
	border-left: 1px solid #c7c7c7;
	height: 40px;
}
.po-line-items th:first-child,
.po-line-items td:first-child {
	border-left: none !important;
}
</style>
