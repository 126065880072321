<template>
	<Dialog
		:dialog="isProductLineItemDialog"
		style="overflow: hidden"
		title="Product Line Item"
		current
		v-on:close="dialog = false"
		:dialogWidth="1024"
	>
		<template v-slot:title>
			<span> Add </span>
			<span class="px-1">
				{{ type == "inventory" ? "Product" : type }}
			</span>
			Line Items ({{productLineItems.length-otherLineItemCount}})
		</template>
		<template v-slot:body>
			<div style="overflow: hidden" class="mb-4 w-100 po-line-items mr-4 bt-table">
				<v-simple-table style="overflow: hidden" class="w-100 table-head-sticky" width="100%">
					<thead>
						<tr>
							<th class="pa-2 text-center" style="font-weight: 600" width="40"></th>
							<!-- <th class="pa-2 text-center" style="font-weight: 600" width="60">#</th> -->
							<th class="pa-2" width="300" style="font-weight: 600">Items</th>
							<th class="pa-2" width="400" style="font-weight: 600">Description</th>
							<th class="pa-2" width="400" style="font-weight: 600">Remark</th>
							<th class="pa-2" width="150" style="font-weight: 600" >Quantity</th>
							<th class="pa-2" width="150" style="font-weight: 600">Request</th>
							<!-- <th class="pa-2" width="50" style="font-weight: 600"></th> -->
						</tr>
					</thead>
					
					<tbody style="overflow: hidden">
						<tr v-for="(row, index) in productLineItems" :key="index" class="">
							<template v-if="row.product_type == type">
								<td class="py-1 px-2 text-center">
									<v-checkbox dense v-model="row.isSelected"></v-checkbox>
								</td>
								<!-- <td class="py-1 px-2 text-center">{{index+1}}</td> -->
								<td class="py-1 px-2">
									<!-- <AutoCompleteInput
										hide-details
										v-if="type == 'equipments'"
										:items="equipmentDropdownList"
										:disabled="1"
										:loading="pageLoading"
										id="group"
										placeholder="Item"
										item-text="text"
										item-value="value"
										v-model="row.id"
										class="mt-0"
									></AutoCompleteInput>
									
									<AutoCompleteInput
										v-else
										hide-details
										:items="productList"
										:disabled="1"
										:loading="pageLoading"
										id="group"
										placeholder="Item"
										item-text="text"
										item-value="value"	
										v-model="row.product"
										class="mt-0"
									></AutoCompleteInput> -->
									<template >{{ row.product_info && row.product_info}}</template>
									<!-- <template v-else>{{ getProductName(row.product) }}</template> -->
								</td>
								<td class="py-1 px-2" max-width="400" :class="row.isEditable ? 'px-2' : 'px-3'">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="row.description"
										class="mt-0"
									></TextInput>
									<!-- <template v-else>{{ row.description }}</template> -->
								</td>
								<td
									class="py-1 px-2"
									max-width="400"
									:class="row.isEditable ? 'px-2' : 'px-3'"
								>
									<TextInput
										hide-details
										:disabled="pageLoading || type != 'equipments'"
										:loading="pageLoading"
										v-model="row.remark"
										class="mt-0"
									></TextInput>
								</td>
								<td class="py-1 px-2" :class="row.isEditable ? 'px-2' : 'px-3'">
									<NumberInput
										:readonly="true"
										:disabled="pageLoading || type == 'equipments'"
										hide-details
										type="number"
										:rules="[vrules.required(row.quantity, 'Quantity')]"
										:class="{
											required: !row.quantity,
										}"
										:loading="pageLoading"
										v-model="row.quantity"
										class="mt-0"
									></NumberInput>
									<!-- <template v-else>{{ row.quantity }}</template> -->
								</td>
								<td class="py-1 px-2" :class="row.isEditable ? 'px-2' : 'px-3'">
									<NumberInput
										hide-details
										type="number"
										:disabled="pageLoading"
										@keyup="lineItemRequestKeyup(row.quantity, row.request_quantity, index)"
										:loading="pageLoading"
										v-model.number="row.request_quantity"
										class="mt-0"
									></NumberInput>
									<!-- <template v-else>{{ row.request }}</template> -->
								</td>
								<!-- <td class="py-1 px-2 text-right">
									<v-icon
										color="red"
										:disabled="productLineItems.length == 1 ? true : false"
										class="cursor-pointer"
										@click="deleteLineItems(index)"
										>mdi-delete-outline</v-icon
									>
								</td> -->
							</template>
						</tr>
					</tbody>
				</v-simple-table>
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" @click="$emit('close')"> Close </v-btn>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				:disabled="pageLoading"
				:loading="pageLoading"
				@click="$emit('saveLineItem',productLineItems)"
			>
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import NumberInput from "@/view/components/NumberInput";
import TextInput from "@/view/components/TextInput";
import "@/core/event-bus/click-outside";
export default {
	model: {
		prop: "productLineItems",
		event: "changeLineItem",
	},
	data() {
		return {
			pageLoading: false,
			otherLineItemCount: 0,
			indexCountValue: 0,
		};
	},
	components: {
		Dialog,
		NumberInput,
		//AutoCompleteInput,
		TextInput,
	},
	props: {
		productLineItems: {
			type: Array,
			default: () => [],
		},
		equipmentDropdownList: {
			type: Array,
			default: () => [],
		},
		isProductLineItemDialog: {
			type: Boolean,
		},
		productList: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
		},
	},
	methods: {
		indexCount(line_item_type) {
			if (line_item_type == this.type) {
				//console.log(line_item_type);
				//console.log(this.type);
				return (this.indexCountValue = this.indexCountValue + 1);
			}
		},
		addServiceLineItem() {
			this.$emit("addServiceLineItem");
		},
		lineItemRequestKeyup(quantity, request, index) {
			if (request > quantity) {
				this.productLineItems[index].request = this.productLineItems[index].quantity;
			}
		},
		deleteLineItems(index) {
			this.productLineItems.splice(index, 1);
		},
	},
	mounted() {
		this.otherLineItemCount = this.productLineItems.filter(
			(ele) => ele.product_type != this.type
		).length;
	},
};
</script>