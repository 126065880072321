import ApiService from "@/core/services/api.service";
export const CreateProjectTransfer = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("project/project-transfer/transfer/control", params)
		
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetProjectList = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("project/project-transfer/transfer/options_lists")
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetProjectTransfer = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/transfer/project_transfer_details/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/budgets/${uuid}/project_transfer_details`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetProjectBudgetLineItems = (uuid, type) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`/project/${uuid}/line-item/item-lists?type=${type}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetProjectEquipmentLineItems = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`/project/equipment/equipment-list/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
}
export const GetEquipmentList = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`/project/equipment/equipment-list/lists`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
}
export const createEquipment = (uuid, type, equipmentsLineItem) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`project/${uuid}/line-item/create?type=${type}`, { line_items: equipmentsLineItem })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
}

//change project status
export const ChangeProjectStatus = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`project-transfer/bulk-action/status`,params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ProjectLineItems = (uuid,projectTransferUuid='') => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/project-transfer/purchase-order-items/${uuid}?project_transfer=${projectTransferUuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const EquipmentAllList = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/equipment/equipment-list/lists`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

