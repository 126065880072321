<template>
	<Dialog
		:dialog="addServiceLineItemDialog"
		style="overflow: hidden"
		title="Product Line Item"
		current
		v-on:close="dialog = false"
		:dialogWidth="1024"
	>
		<template v-slot:title> Add Service Line Items </template>
		<template v-slot:body>
			<div style="overflow: hidden" class="mb-4 w-100 po-line-items mr-4 bt-table">
				<v-simple-table style="overflow: hidden" class="w-100 table-head-sticky" width="100%">
					<thead>
						<tr>
							<th class="pa-2 text-center" style="font-weight: 600" width="40"></th>
							<th class="pa-2 text-center" style="font-weight: 600" width="60">#</th>
							<th class="pa-2" width="300" style="font-weight: 600">Items</th>
							<th class="pa-2" width="400" style="font-weight: 600">Description</th>
							<th class="pa-2" width="150" style="font-weight: 600">Quantity</th>
							<th class="pa-2" width="150" style="font-weight: 600">Request</th>
							<th class="pa-2" width="50" style="font-weight: 600"></th>
						</tr>
					</thead>
					<tbody style="overflow: hidden">
						<tr v-for="(row, index) in serviceLineItems" :key="index" class="">
							<td class="py-1 px-2 text-center">
								<v-checkbox dense v-model="row.isSelected"></v-checkbox>
							</td>
							<td class="py-1 px-2 text-center">{{ index + 1 }}</td>
							<td class="py-1 px-2" :class="row.isEditable ? 'px-2' : 'px-3'">
								<AutoCompleteInput
									hide-details
									:items="productList"
									:disabled="pageLoading"
									:loading="pageLoading"
									id="group"
									placeholder="select Item"
									item-text="text"
									item-value="value"
									v-model="row.product"
									class="mt-0"
								></AutoCompleteInput>
								<!-- <template v-else>{{ getProductName(row.product) }}</template> -->
							</td>
							<td class="py-1 px-2" max-width="400" :class="row.isEditable ? 'px-2' : 'px-3'">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="row.description"
									class="mt-0"
								></TextInput>
								<!-- <template v-else>{{ row.description }}</template> -->
							</td>
							<td class="py-1 px-2" :class="row.isEditable ? 'px-2' : 'px-3'">
								<NumberInput
									disabled
									hide-details
									type="number"
									:rules="[vrules.required(row.quantity, 'Quantity')]"
									:class="{
										required: !row.quantity,
									}"
									:loading="pageLoading"
									v-model="row.quantity"
									class="mt-0"
								></NumberInput>
								<!-- <template v-else>{{ row.quantity }}</template> -->
							</td>
							<td class="py-1 px-2" :class="row.isEditable ? 'px-2' : 'px-3'">
								<NumberInput
									hide-details
									type="number"
									:disabled="pageLoading"
									@keyup="lineItemRequestKeyup(row.quantity, row.request, index)"
									:loading="pageLoading"
									v-model.number="row.request"
									class="mt-0"
								></NumberInput>
								<!-- <template v-else>{{ row.request }}</template> -->
							</td>
							<td class="py-1 px-2 text-right">
								<v-icon
									color="red"
									:disabled="serviceLineItems.length == 1 ? true : false"
									class="cursor-pointer"
									@click="deleteLineItems(index)"
									>mdi-delete-outline</v-icon
								>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" @click="$emit('close')"> Close </v-btn>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				:disabled="pageLoading"
				:loading="pageLoading"
				@click="$emit('close')"
			>
				<!-- {{ addServiceLineItemDialog ? "Update" : "Save" }} -->
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import NumberInput from "@/view/components/NumberInput";
import TextInput from "@/view/components/TextInput";
import "@/core/event-bus/click-outside";
export default {
	model: {
		prop: "serviceLineItems",
		event: "changeLineItem",
	},
	data() {
		return {
			pageLoading: false,
		};
	},
	components: {
		Dialog,
		NumberInput,
		AutoCompleteInput,
		TextInput,
	},
	props: {
		serviceLineItems: {
			type: Array,
			default: () => [],
		},
		addServiceLineItemDialog: {
			type: Boolean,
		},
		productList: {
			type: Array,
			default: () => [],
		},
	},
	// watch(())
	methods: {
		addServiceLineItem() {
			this.$emit("addServiceLineItem");
		},
		lineItemRequestKeyup(quantity, request, index) {
			if (request > quantity) {
				this.serviceLineItems[index].request = this.serviceLineItems[index].quantity;
			}
		},
			deleteLineItems(index) {
			this.serviceLineItems.splice(index, 1);
		},
	},
};
</script>